<template>
  <div class="container">
    <div class="footbut">
      <el-button type="warning" @click="save" size="small">保存</el-button>
    </div>
    <div class="newForm team">
      <el-form
        ref="selectForm"
        :model="selectForm"
        label-width="100px"
      >
        <div class="queryItem1">
          <el-form-item label="团号">
            <el-input
              v-model="selectForm.invitationCode"
              clearable
              placeholder="请输入团号"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="newForm search">
      <el-form
        ref="searchForm"
        :model="searchForm"
        :rules="searchForm"
        label-width="100px"
      >
        <div class="inlineDiv">
          <div class="queryItem1">
            <el-form-item label="运单号:" prop="courierNumber">
              <el-input
                v-model="searchForm.courierNumber"
                clearable
                placeholder="请输入运单号"
              ></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="会员号:" prop="memberId">
              <el-input
                v-model="searchForm.memberId"
                clearable
                placeholder="请输入会员号"
              ></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="仓库:" prop="storageId">
              <el-select
                v-model="searchForm.storageId"
                clearable
                placeholder="请选择仓库"
              >
                <el-option
                  v-for="item in storeArr"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-button type="warning" size="mini" @click="cx">查 询</el-button>
        </div>
      </el-form>
    </div>
    <div class="transfer">
      <el-row>
        <el-col :span="11">
          <div class="leftTable">
            <div class="titleTip">未参加拼团</div>
            <el-table
              :data="leftTable"
              size="mini"
              stripe
              highlight-current-row
              :show-overflow-tooltip="true"
              :max-height="490"
              border
              @selection-change="notJoinSelectionChange"
              style="width: 100%"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="50"
              >
              </el-table-column>
              <el-table-column type="selection" align="center" width="55">
              </el-table-column>
              <el-table-column
                prop="courierNumber"
                align="center"
                label="快递单号"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="storageName"
                align="center"
                label="仓库"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="nickname"
                label="会员名称"
                align="center"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="memberId"
                align="center"
                min-width="100"
                label="会员号"
              >
              </el-table-column>
            </el-table>
            <paging
              ref="pags"
              :pageNum="pageStart"
              :total="pageCount"
              :sizeList="sizeList"
              :size="pegeTotal"
              @handleSizeChange="notJionSize"
              @handleCurrentChange="notJoinCurrent"
            ></paging>
          </div>
        </el-col>
        <el-col :span="2">
          <div class="btnBox">
            <el-button
              type="primary"
              size="small"
              style="margin-bottom: 40px"
              @click="theLeft"
              :disabled="status === 0"
              icon="el-icon-arrow-left"
            >
              向左移
            </el-button>
            <el-button
              type="success"
              size="small"
              :disabled="status === 1"
              @click="theRight"
            >
              向右移 <i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </div>
        </el-col>
        <el-col :span="11">
          <div class="rightTable">
            <div class="titleTip">已参加拼团</div>
            <el-table
              :data="rightTable"
              stripe
              border
              :max-height="490"
              size="mini"
              @selection-change="joinSelectionChange"
              style="width: 100%"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="50"
              >
              </el-table-column>
              <el-table-column type="selection" align="center" width="55">
              </el-table-column>
              <el-table-column
                prop="courierNumber"
                align="center"
                label="快递单号"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="storageName"
                align="center"
                label="仓库"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="nickname"
                label="会员名称"
                align="center"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="memberId"
                align="center"
                min-width="100"
                label="会员号"
              >
              </el-table-column>
            </el-table>
            <paging
              ref="pags"
              :pageNum="pageStartR"
              :total="pageCountR"
              :sizeList="sizeList"
              :size="pageTotalR"
              @handleSizeChange="joinSize"
              @handleCurrentChange="joinCurrent"
            ></paging>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import paging from "@/components/pagings.vue";

export default {
  components: { paging },
  data() {
    return {
      selectForm: {}, //  提交的数据
      searchForm: {}, //  表格搜索数据
      storeArr: [], //  仓库数组
      leftTable: [], //  左边表格数据
      sizeList: [10, 20, 50, 100, 200], //  分页配置
      pageStart: 1, //  分页配置-左边
      pegeTotal: 20,
      pageCount: 0,
      rightTable: [], //  右边表格数据
      pageStartR: 1, //  分页配置-右边
      pageTotalR: 20,
      pageCountR: 0,
      status: 2,
      selectedData: [], //  表格选中的数据
    };
  },
  created() {
    this.selectForm.invitationCode = this.$route.query.code;
    this.selectForm.packageTeamId = this.$route.query.id;
    this.myGetStorageList(); //  获取仓库下拉列表
    this.getNotJoinTeamList(); //  获取未入团列表
    this.getJoinTeamList(); //  获取已入团列表
  },
  methods: {
    myGetStorageList() {
      //  获取仓库下拉列表
      Api.getStorageList().then((res) => {
        if (res.data.status === "success") {
          this.storeArr = res.data.result || [];
        } else {
          this.$message.error(`${res.data.message}--仓库下拉列表`);
        }
      });
    },

    getNotJoinTeamList() {
      //  获取未入团列表
      this.searchForm.pageStart = this.pageStart;
      this.searchForm.pegeTotal = this.pegeTotal;
      Api.notJoinTeamList(this.searchForm).then((res) => {
        if (res.data.status === "success") {
          this.leftTable = res.data.result.data || [];
          this.pageCount = res.data.result.pageCount || 0;
        } else {
          this.$message.error(`${res.data.message}--未入团列表`);
        }
      });
    },

    getJoinTeamList() {
      //  获取已入团列表
      Api.joinTeamList({
        pageStart: this.pageStartR,
        pegeTotal: this.pegeTotalR,
        packageTeamId: this.selectForm.packageTeamId,
      }).then((res) => {
        if (res.data.status === "success") {
          this.rightTable = res.data.result.data || [];
          this.pageCount = res.data.result.pageCount || 0;
        } else {
          this.$message.error(`${res.data.message}--未入团列表`);
        }
      });
    },

    cx() {
      //  未入团搜索查询
      this.pageStart = 1;
      this.getNotJoinTeamList();
    },
    notJoinSelectionChange(e) {
      //  未入团表格选择
      this.selectedData = e;
      this.status = 0;
    },
    joinSelectionChange(e) {
      //  已入团表格选择
      this.selectedData = e;
      this.status = 1;
    },
    theLeft() {
      //  向左移动
      if (this.selectedData.length > 0) {
        this.selectedData.forEach((item) => {
          let index = this.rightTable.findIndex((r) => {
            return r.packageId === item.packageId;
          });
          this.leftTable.push(item);
          this.rightTable.splice(index, 1);
        });
      } else {
        this.$message.warning("当前无数据！");
      }
    },
    theRight() {
      //  向右移动
      if (this.selectedData.length > 0) {
        this.selectedData.forEach((item) => {
          let index = this.leftTable.findIndex((r) => {
            return r.packageId === item.packageId;
          });
          this.rightTable.push(item);
          this.leftTable.splice(index, 1);
        });
      } else {
        this.$message.warning("当前无数据！");
      }
    },
    notJionSize(val) {
      //  分页配置
      this.pageTotal = val;
      this.getNotJoinTeamList();
    },
    notJoinCurrent(val) {
      this.pageStart = val;
      this.getNotJoinTeamList();
    },
    joinSize(val) {
      //  分页配置
      this.pageTotalR = val;
      this.getJoinTeamList();
    },
    joinCurrent(val) {
      this.pageStartR = val;
      this.getJoinTeamList();
    },
    save() {
      //  提交数据
      delete this.selectForm.invitationCode;
      this.selectForm.packageId = [];
      this.rightTable.forEach((item) => {
        this.selectForm.packageId.push(item.packageId);
      });
      let pram = {
        packageId: this.selectForm.packageId,
        packageTeamId: this.selectForm.packageTeamId,
      };
      let sign = tools.getSign(pram);
      pram.sign = sign;
      Api.addPackageTeamPackage(pram).then((res) => {
        if (res.data.status === "success") {
          this.$message.success(res.data.message);
          tools.closePage();
          this.reload();
        } else {
          this.$message.error(`${res.data.message}--提交数据`);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.team {
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px silver solid;
  .queryItem1 {
    /deep/ .el-radio {
      margin-right: 0;
      margin-left: 10px;
    }
  }
}

.search {
  border-bottom: 1px silver solid;
  padding: 20px 0;
  .queryItem1 {
    width: 20%;
  }
}

.container {
  margin: 0 20px;
}

.btnBox {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(200%);
}

.leftTable {
  padding-right: 16px;
  height: 590px;
  border-right: 1px silver solid;
}

.rightTable {
  padding-left: 16px;
  border-left: 1px silver solid;
  height: 590px;
}

.transfer {
  border-bottom: 1px silver solid;
}

.footbut {
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: 10px;
}

.titleTip {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #888888;
}
</style>
